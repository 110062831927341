a:link {
  color: #e6526c;
  text-decoration: none;
}

.site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
.event-share .container {
  flex: 1;
}
.bg-content {
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(6px) grayscale(100%);
  -webkit-backdrop-filter: blur(10px);
  margin-top: -0.1em;
  display: flex;
  flex-direction: column;
}
.bg-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-grow: 1;
  display: flex;
}
.main-content {
  background-color: rgba(255, 255, 255, 1);
  padding: 0;
}
.logo-icon {
  text-align: center;
}
.dateTime a {
  color: #e6526c;
}
.logo-icon img {
  border-radius: 15px;
}
.mrkmssg {
  text-align: center;
  font-size: 22px;
}
.cta {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
}
.badge-right {
  text-align: right;
}
.badge-right,
.badge-left {
  padding-top: 20px;
}
.sectitle {
  font-weight: 500;
}
.dateTime {
  font-size: 16px;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 0;
}
h2 {
  font-size: 42px;
  text-align: center;
  padding-bottom: 6px;
  font-weight: 800;
  margin-left: 0;
  padding-left: 0;
}
h6 {
  margin-left: 0;
  padding-left: 0;
}
.details {
  font-size: 18px;
  font-weight: normal;
  margin: 0px;
  margin-bottom: 1.5rem;
  overflow-wrap: break-word;
}
.hashtags {
  font-size: 18px;
  font-weight: normal;
  margin: 0px;
  margin-bottom: 3rem;
  display: flex;
  flex-wrap: wrap;
}
.publisher {
  font-size: 18px;
  margin-bottom: 1.5rem;

  @media (max-width: 991.98px) {
    font-size: 12px;
  }
}
.username {
  font-weight: 900;
}
.participation {
  font-size: 18px;
  text-align: center;
}
.categories {
  font-size: xx-small;
  color: grey;
}
.pad0 {
  padding: 0px;
  margin: 0px;
}
a:link,
a:visited,
a:hover,
a:active {
  color: #e6526c;
}
.assocGrpList h6 {
  font-size: 18px;
  color: gray;
  margin-bottom: 16px;
}

.show-task-shift {
  padding: 16px 0;
  .accordion-button {
    color: black;
  }
}
.task-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 6px;
  padding-bottom: 20px;
  margin-bottom: 10px;
  border-bottom: solid 2px var(--CRMGRAY);

  .task-title {
    display: inline-block;
    font-size: 16px;
    margin-bottom: 6px;
    font-weight: 500;
    color: #4a4a4a;
  }
}
.shift-box {
  align-items: center;
  border-top: 1px solid var(--CRGRAY);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 6px 0;

  .shift-text {
    color: #4a4a4a;
    display: inline-block;
    font-size: 14px;
  }

  .shift-time {
    flex: 9;
    padding-left: 1rem;
  }

  .shift-numbers {
    flex: 2;
  }

  .shift-check {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
  }
}

.shift-box:last-child {
  border-bottom: 1px solid var(--CRGRAY);
}

.show-task-shift-header {
  padding-bottom: 0px;
}

.show-task-shift-body {
  padding: 1rem 0.5rem;
}

/* this is hidding content that is generated and added after footer */
.alert,
.notice {
  display: none;
}

.categoryList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

.sponsorAdMessageContainer {
  margin-top: 2rem;
}

.sponsorAdMessage {
  margin-bottom: 1rem;
}

.event-privacy {
  color: gray;
  font-size: 0.75rem;
}

.serviceHours {
  font-size: 1rem;
  margin-top: 1rem;
  text-align: center;
}

.status-bar {
  display: flex;
  justify-content: flex-end;
  align-content: flex-end;
  align-items: center;
}

.sponsored-by {
  text-transform: uppercase;
  color: gray;
  font-weight: 400;
  font-size: smaller;
}

.link {
  font-size: smaller;
  text-decoration: underline !important;
}

.mainImage {
  max-height: 400px;
  contain: content;
}
.title {
  margin-top: 2rem;
}

.main-section {
  display: flex;
  flex-wrap: wrap;
}

.col1,
.col2 {
  box-sizing: border-box;
}

.col1 {
  flex: 0 0 65%;
  padding: 0 2rem;
}

.col2 {
  flex: 0 0 35%;
}

.item-title {
  padding-top: 20px !important;
}

.item-qr,
.item-sponsor {
  margin: 0 3rem;
}

.qr-section {
  position: relative;
  top: -120px;
  margin-bottom: -120px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 1);
  padding-top: 2rem;
}

.bottom-banner {
  background-image: url('../../../public/images/event-bottom-banner.png');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 258px;
}

.groups-event-link:not(:last-child):after {
  content: ', ';
}

/* update sizes for smaller devices */
@media (max-width: 991.98px) {
  h2 {
    font-size: 26px;
    font-weight: 800;
  }
  .main-content {
    max-width: 960px;
  }
  .mainImage {
    max-height: 250px;
  }
  .details {
    font-size: 14px;
    padding-bottom: 14px;
    margin: 0px;
    overflow-wrap: break-word;
  }
  .participation {
    font-size: 16px;
    text-align: center;
  }
  .groupStats,
  .groupOwner {
    font-size: 12px;
  }
  .assocGrpList h6 {
    font-size: 14px;
  }
  .assocGrpList li {
    font-size: 12px;
  }
  .assocGrpList ul {
    margin-bottom: 20px;
  }
  .assocGrpPic {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    margin-right: 5px;
    border: 1px solid gray;
  }
  .col1 {
    padding: 0 1rem;
  }
  .item-qr,
  .item-sponsor {
    margin: 0 1.5rem;
  }
  .qr-section {
    position: relative;
    top: -100px;
    margin-bottom: -100px;
    border-radius: 15px;
    padding-top: 1rem;
  }
}

@media (max-width: 767.98px) {
  h2 {
    font-size: 26px;
    font-weight: 800;
  }
  .bg-content {
    padding: 0 !important;
  }
  .main-content {
    max-width: 95%;
  }
  .mainImage {
    max-height: 250px;
  }
  .details {
    font-size: 14px;
    padding-bottom: 14px;
    margin: 0px;
    overflow-wrap: break-word;
  }
  .participation {
    font-size: 16px;
    text-align: center;
  }
  .groupStats,
  .groupOwner {
    font-size: 12px;
  }
  .assocGrpList h6 {
    font-size: 14px;
  }
  .assocGrpList li {
    font-size: 12px;
  }
  .assocGrpList ul {
    margin-bottom: 20px;
  }
  .assocGrpPic {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    margin-right: 5px;
    border: 1px solid gray;
  }
  .col1 {
    padding: 0 0.5rem;
  }
  .item-qr,
  .item-sponsor {
    margin: 0 0.5rem;
  }
  .qr-section {
    position: relative;
    top: -100px;
    margin-bottom: -100px;
    border-radius: 15px;
    padding-top: 1rem;
  }
  .categoryList {
    margin-top: 1rem;
  }
}

@media (max-width: 575.98px) {
  h2 {
    font-size: 26px !important;
    font-weight: 800 !important;
  }
  .bg-content {
    padding: 0 !important;
  }
  .main-content {
    max-width: 100%;
  }
  .mainImage {
    max-height: 200px;
  }
  .volunteer-info-label {
    font-size: 14px !important;
  }
  .details,
  .volunteer-info-content {
    font-size: 18px !important;
  }
  .main-section {
    display: grid;
    grid-template-columns: 1fr;
  }

  .col1,
  .col2 {
    display: contents;
  }

  .item-title {
    order: 1;
  }
  .item-detail {
    order: 3;
  }
  .item-category {
    order: 5;
  }
  .item-qr {
    order: 2;
    margin: 0;
  }
  .item-sponsor {
    order: 4;
    margin: 0;
  }

  .qr-section {
    position: unset;
    top: 0;
    margin-bottom: 0;
  }

  .bottom-banner {
    background-image: url('../../../public/images/event-bottom-banner-mobile.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 258px;
  }
  .item {
    padding: 0 10px;
  }
}

.footer-print, .logo-print {
  display: none;
}

.html-content {
  p {
    padding-bottom: 1rem !important;
  }
  ol, ul {
    padding-left: 1rem !important;
  }
  ol {
    list-style: decimal;
  }
}

/* print styles */
@media print {
  body {
    color: #000;
    background-color: #fff;
  }
  .title {
    margin-top: 0;
  }
  .footer-print {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    padding-right: 1rem;
    .col2 {
      display: flex;
      justify-content: flex-end;
    }
    img {
      width: 301px * 0.75;
      height: 120px * 0.75;
    }
  }
  .logo-print{
    margin-bottom: 10px;
    display: flex;
  }
  .navbar,
  .no-print,
  .footer,
  .mainfooter,
  .subfooter {
    display: none;
  }
  * {
    background-image: none !important;
  }
}
